import BaseModel from '../../../../logic/model/BaseModel';
import Api from '../../../../logic/api/Api';

export default class DevicesModel extends BaseModel {

  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(params) {
    return `admin/users/${params.userId}/user-devices`;
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'user-device/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'user-device/save';
  }


  async delete(id, lastUsedIp, providedDeviceId) {
    console.log("id, lastUsedIp, providedDeviceId:", id, lastUsedIp, providedDeviceId)
    const result = await Api.call(
     `admin/users/user-devices/${id}/logout`, 'PATCH', {
        lastUsedIp, providedDeviceId
      }
    );
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'providedDeviceId',
        label: 'Device ID',
        sortable: false,
        type: 'field'
      },
      {
        name: 'deviceOs',
        label: 'Device OS',
        sortable: false,
        type: 'field'
      },
      {
        name: 'lastUsedIp',
        label: 'Last Used Ip',
        sortable: false,
        type: 'field'
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ]
  }
}
