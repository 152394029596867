import React from 'react';
import CustomIcon from './CustomIcon';

export default class AppIcon extends React.Component<any, any> {
  render() {
    const props = { ...this.props };

    if (!props.name) {
      return null;
    }

    let type = 'custom';
    let { name } = props;

    const nameSplit = name.split('.');

    if (nameSplit.length == 2) {
      type = nameSplit[0];
      name = nameSplit[1];
    }

    props.name = name;

    switch (type) {
      case 'md':
        return <i className={`fa fa-fw fa-${name}`} {...props} />;
      case 'fa':
        return <i className={`fa fa-fw fa-${name}`} {...props} />;
      case 'custom':
        return <CustomIcon {...props} />;
    }

    return null;
  }
}
