import React, { Component } from 'react';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '../../../../../components/crud/DeleteButton';
import DevicesModel from '../../models/DevicesModel';
import ModalDialog from '../../../../../components/modal/ModalDialog';
import BreadcrumbHelper from '../../../../../util/BreadcrumbHelper';

export default class UsersDevicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false
    };
    BreadcrumbHelper.setCrumbs([
      {
        label: 'User Devices'
      }
    ]);
  }

  onClose() {
    this.setState({
      showInfoModal: false
    });
  }


  onDeleteItem(id, lastUsedIp, providedDeviceId) {
    new DevicesModel()
      .delete(id, lastUsedIp, providedDeviceId)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true
        });
      });
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    if(!this.props.user?.id){
      return (
        <div className="dashboard-management">
        <h1>
          <i className="fa fa-laptop" aria-hidden="true" /> User Devices
        </h1>
        </div>
      )
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-laptop" aria-hidden="true" /> User Devices
        </h1>
        <AppTable
          title="User Devices"
          params={{
            userId: this.props.user.id
          }}
          onInit={tableComponent => (this._tableComponent = tableComponent)}
          filter={[
            {
              name: 'term',
              type: 'term'
            }
          ]}
          model={DevicesModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                {member.status === 'ACTIVE' && (
                  <DeleteButton
                  onConfirm={() => this.onDeleteItem(member.id, member.lastUsedIp, member.providedDeviceId)}
                  btnText="Deactivate"
                  text={
                    `Are you sure you wish to deactivate device "${
                      member.deviceOs 
                    }"?`
                  }
                  />
                  )}
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={this.onClose.bind(this)}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              You can not delete this device!
            </p>
            <button onClick={this.onClose.bind(this)} className="button-info"  type="button">
              OK
            </button>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

