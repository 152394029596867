import { LPOverridable } from '@launchpad/components';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import BreadcrumbHelper from '../../../util/BreadcrumbHelper';

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crumbs: BreadcrumbHelper.crumbs
    };

    BreadcrumbHelper.onUpdate(crumbs => {
      this.setState({
        crumbs
      });
    });
  }

  render() {
    const elements = [];
    elements.push(
      <LPOverridable name="breadcrumb-home-item" key="home-link">
        <Link
          key="linkHome"
          to="/"
          className="breadcrumb-item"
          onClick={() =>
            window.localStorage.setItem('APP_TABS_ACTIVE_TAB', null)
          }
        >
          Home
        </Link>
      </LPOverridable>
    );

    for (let i = 0; i < this.state.crumbs.length; i++) {
      const crumb = this.state.crumbs[i];

      if (crumb.link) {
        if (crumb.goBack) {
          elements.push(
            <Link
              to={crumb.link}
              key={`link${i}`}
              className="breadcrumb-item"
              onClick={() => [
                this.props.history.goBack(),
                window.localStorage.setItem('APP_TABS_ACTIVE_TAB', null)
              ]}
            >
              {crumb.label}
            </Link>
          );
        } else {
          elements.push(
            <Link
              key={`link${i}`}
              to={crumb.link}
              className="breadcrumb-item"
              onClick={() =>
                window.localStorage.setItem('APP_TABS_ACTIVE_TAB', null)
              }
            >
              {crumb.label}
            </Link>
          );
        }
      } else {
        elements.push(
          <span key={`link${i}`} className="breadcrumb-item active">
            {crumb.label}
          </span>
        );
      }
    }

    return (
      <nav
        className="breadcrumb"
        style={{ height: 10, background: 'transparent' }}
      >
        {elements}
      </nav>
    );
  }
}

export default withRouter(Breadcrumbs);
