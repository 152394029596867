import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'fx';

export default class FxRatesModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'fx.*';
  }

  getNavigation() {
    return [
      {
        label: 'FX Rates',
        icon: 'retweet',
        path: '/fx-rates',
        permission: 'fx.*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}fx-rates`}
        load={() => import('./FxRates')}
      />
    ];
  }
}
