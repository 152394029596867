import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';
import AddUserGroup from './user-groups/AddUserGroup';
import UserGroupDetails from './user-groups/UserGroupDetails';
import UserGroupEdit from './user-groups/UserGroupEdit';
import UserGroupsList from './user-groups/UserGroupsList';
import PushDashboard from './PushDashboard';

export const NAME = 'notifications';

export default class NotificationsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'notifications.*';
  }

  getNavigation() {
    return [
      {
        label: 'Notifications',
        icon: 'bell',
        // path: '/push-notifications',
        permission: 'notifications.*',
        children: [
          {
            label: 'Push dashboard',
            path: '/push-dashboard',
            icon: 'dashboard',
            permission: 'notifications.dashboard.*'
          },
          {
            label: 'Send new notification',
            path: '/push-notifications/add-new',
            icon: 'plus',
            permission: 'notifications.send.*'
          },
          {
            label: 'Sent notifications',
            path: '/push-notifications',
            icon: 'paper-plane',
            permission: 'notifications.sent.*'
          },
          {
            label: 'User Groups',
            path: '/user-groups',
            icon: 'users',
            permission: 'notifications.groups.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}push-dashboard`}
        component={PushDashboard}
      />,
      <AppRoute
        path={`${currentUrl}push-notifications`}
        load={() => import('./PushNotificationsPage')}
      />,
      <AppRoute
        path={`${currentUrl}user-groups/add`}
        component={AddUserGroup}
      />,
      <AppRoute
        path={`${currentUrl}user-groups/details/:id`}
        component={UserGroupDetails}
      />,
      <AppRoute
        path={`${currentUrl}user-groups/edit/:id`}
        component={UserGroupEdit}
      />,
      <AppRoute
        path={`${currentUrl}user-groups`}
        exact
        component={UserGroupsList}
      />
    ];
  }
}
