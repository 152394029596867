import logger from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import appReducers from './AppReducers';
import appSagas from './AppSagas';
import qs from 'qs';
import Config from '../../config';

// export const history = createHistory();
export const history = createBrowserHistory();

const appendQueryToLocation = () => {
  history.location = Object.assign(
    history.location,
    // parse the search string using your package of choice
    { query: qs.parse(history.location.search, { ignoreQueryPrefix: true }) }
  );
};
appendQueryToLocation();
history.listen(() => {
  appendQueryToLocation();
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

let appStore;

if (Config.env === 'dev') {
  appStore = createStore(
    appReducers(history),
    composeWithDevTools(
      applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
    )
  );
} else {
  appStore = createStore(
    appReducers(history),
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );
}

const appStore2 = appStore;

sagaMiddleware.run(appSagas);

export default appStore2;
