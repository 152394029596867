import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'dictionary';

export default class DictionaryModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'dictionary.*';
  }

  getNavigation() {
    return [
      {
        label: 'Dictionary',
        icon: 'file',
        path: '/dictionary',
        permission: 'dictionary.*'
      }
    ];
  }

  getRoutes() {
    return [
      <AppRoute exact path="/dictionary" load={() => import('./Dictionary')} />
    ];
  }
}
