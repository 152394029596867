import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from '../../index';
import AppTopBar from './components/AppTopBar';
import AppSideBar from './components/AppSideBar';
import BreadcrumbHelper from '../../util/BreadcrumbHelper';
import Breadcrumb from '../elements/shared/Breadcrumbs';
import ModuleService from '../../logic/module/ModuleService';

class DefaultLayout extends Component<any, any> {
  moduleService: ModuleService;

  modules: any[];

  constructor(props: any) {
    super(props);

    this.moduleService = new ModuleService();

    this.modules = this.moduleService.getModules();

    this.state = {};
  }

  /**
   * Clear breacrumbs
   */
  UNSAFE_componentWillUpdate() {
    BreadcrumbHelper.clearCrumbs();
  }

  /**
   * Fast fix for top scrolling
   */
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  _getInnerElements() {
    return (
      <Switch>{this.moduleService.getRoutes(this.props.match.url)}</Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    const innerElements = this._getInnerElements();

    return (
      <div>
        <AppTopBar />
        <div className="dashboard">
          <AppSideBar />
          <Breadcrumb />
          <div className="dashboard-main">{innerElements}</div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}
export default DefaultLayout;
