import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'rewards';

export default class RewardsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'rewards.*';
  }

  getNavigation() {
    return [
      {
        label: 'Rewards',
        icon: 'trophy',
        path: null,
        permission: 'rewards.*',
        children: [
          {
            label: 'Retailers',
            icon: 'registered',
            path: '/rewards/retailers',
            permission: 'rewards.retailers.*'
          },
          {
            label: 'Promotions',
            icon: 'product-hunt',
            path: '/rewards/promotions',
            permission: 'rewards.promotions.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}rewards`}
        load={() => import('./RewardPage')}
      />
    ];
  }
}
