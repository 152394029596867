import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'user.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'user.*',
        children: [
          {
            label: 'Customers',
            path: '/users/customers',
            icon: 'building',
            permission: 'user.view'
          },
          {
            label: 'Users',
            path: '/users/all-users',
            icon: 'users',
            permission: 'user.view'
          },
          {
            label: 'User groups',
            path: '/user-groups',
            icon: 'file',
            permission: 'user.groups.*'
          },
          {
            label: 'Roles',
            path: '/users/roles',
            icon: 'user-plus',
            permission: 'user.roles.*'
          },
          {
            label: 'Permissions',
            path: '/users/permissions',
            icon: 'key',
            permission: 'user.permissions.*'
          },
          {
            label: 'User Devices',
            path: '/users/user-devices',
            icon: 'laptop',
            permission: 'user.devices.*'
          },
          {
            label: 'Users Logs',
            path: '/users/users-logs',
            icon: 'file',
            permission: 'user.logs.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users`}
        load={() => import('./UsersPage')}
      />
    ];
  }
}
