import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'cards';

export default class CardsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'card.*';
  }

  getNavigation() {
    return [
      {
        label: 'Cards',
        icon: 'credit-card',
        path: null,
        permission: 'cards.*',
        children: [
          {
            label: 'Cards',
            icon: 'credit-card',
            path: '/cards',
            permission: 'cards.view'
          },
          {
            label: 'Card Products',
            path: '/cards/card-products',
            icon: 'product-hunt',
            permission: 'cards.products'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path={`${currentUrl}cards`} load={() => import('./Cards')} />
    ];
  }
}
