import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import LoginPage from '../../modules/auth/pages/LoginPage';
import ForgotPassword from '../../modules/auth/pages/ForgotPassword';
import OTPPage from '../../modules/auth/pages/OTPPage';
import { AppRoute, LPOverridable } from '../../index';

export default class PublicLayout extends Component {
  constructor(props) {
    super(props);
    this.match = props.match;
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      // this.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid login-page">
          <LPOverridable name="login.page.logo">
            <img
              className="login-logo"
              src={require('../../assets/Launchpad_logoWhite.png')}
              alt=""
            />
          </LPOverridable>
          <LPOverridable name="login.page.text">
            <h1>
              Welcome to{' '}
              <span style={{ display: 'block', textAlign: 'center' }}>
                BankVault
              </span>
            </h1>
          </LPOverridable>
          <div className="login-box">
            <div className="white-blur" />
            <div className="inner">
              <Switch>
                <AppRoute
                  path="/forgot-password"
                  component={
                    OverrideService.getOverrides()[
                      'forgot-password-override-page'
                    ] || ForgotPassword
                  }
                />

                <AppRoute
                  path="/login"
                  exact
                  component={
                    OverrideService.getOverrides()['login-override-page'] ||
                    LoginPage
                  }
                />

                <AppRoute path="/login/otp" component={OTPPage} />
              </Switch>
            </div>
          </div>
        </div>
        <footer className="public-layout-footer">
          <p>©{new Date().getFullYear()} Pannovate. All rights reserved.</p>
          <p>European Patent Application No. 14704638.7</p>
        </footer>
      </div>
    );
  }
}
