import React from 'react';
import { withRouter } from 'react-router';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import BootstrapDateTimePicker from 'eonasdan-bootstrap-datetimepicker';
import DatetimepickerCSS from '../../../styles/bootstrap/bootstrap-datetimepicker.css';
import AppSelect from '../AppSelect';

class AppTableFilter extends React.Component {
  _debounceFilter = _.debounce(() => this._triggerFilter(), 200);

  constructor(props) {
    super(props);

    this.state = {
      filter: this._getInitialFilter(props)
    };

    props.onInit(this);
  }

  componentDidMount() {
    $('.filter-datetime').each((index, element) => {
      $(element)
        .datetimepicker({
          useCurrent: false,
          format: this.props.format,
          maxDate: moment().endOf('day'),
          widgetPositioning: {
            horizontal: this.props.horizontalCalendarPosition || 'auto',
            vertical: this.props.verticalCalendarPosition || 'auto'
          }
        })
        .on('dp.change', e => {
          const filter = this.state.filter[
            $(e.target)
              .closest('.single-filter-field')
              .data('index')
          ];
          if (!filter || filter.length === 0) return;

          const value = e.date.format ? e.date.format(this.props.format) : null;
          if (e.date) {
            setTimeout(() => {
              $(element)
                .data('DateTimePicker')
                .hide();
              this._changedFilter(filter, value, false, e.date.toDate());
            }, 50);
          }
        });
    });
  }

  getFilter() {
    return this.state.filter;
  }

  /**
   *
   * @param {Object} props Passed props
   */
  _getInitialFilter(props) {
    let filter = null;
    if (props.location.query.value) {
      filter = this._joinFilters(props.filter, props.location.query.value);
    } else {
      filter = props.filter;
    }

    return this._prepareFilter(filter);
  }

  _joinFilters(filter, otherFilters) {
    const mainFilter = [...filter];

    otherFilters.forEach(otherFilter => {
      const existingFilter = mainFilter.find(x => x.name === otherFilter.name);
      if (existingFilter) {
        const indexOf = mainFilter.indexOf(existingFilter);
        mainFilter[indexOf] = otherFilter;
        // mainFilter[indexOf] = {...existingFilter, ...otherFilter};
      } else {
        mainFilter.push(otherFilter);
      }
    });
    return mainFilter;
  }

  _prepareFilter(filters) {
    if (!filters) return [];

    filters.forEach((filter, index) => {
      if (
        filters[index].value === null ||
        typeof filters[index].value === 'undefined'
      ) {
        filters[index].value = '';
      }
    });

    return filters;
  }

  _getElements(props) {
    const elements = [];

    if (!props.filter || props.filter.length === 0) {
      return elements;
    }

    props.filter.forEach((singleFilter, index) => {
      let innerElement = null;
      switch (singleFilter.type) {
        case 'term':
          innerElement = this._getTermField(singleFilter, index);
          break;
        case 'datetime':
          innerElement = this._getDatetimeField(singleFilter, index);
          break;
        case 'select':
          innerElement = this._getSelectField(singleFilter, index);
          break;
        default:
          return;
      }

      if (innerElement) {
        elements.push(
          <div
            style={{ display: 'inline-block' }}
            className="single-filter-field"
            data-index={index}
            key={`filter${index}`}
          >
            {innerElement}
          </div>
        );
      }
    });

    return elements;
  }

  _getTermField(singlefilter, index) {
    const label = singlefilter.label ? singlefilter.label : null;
    return (
      <div className="filter-field" data-index={index}>
        <label>{label}</label>
        <input
          type="search"
          placeholder="Search"
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
          onChange={e => {
            this._changedFilter(singlefilter, e.target.value);
            this._debounceFilter();
          }}
        />
      </div>
    );
  }

  _getDatetimeField(singleFilter, index) {
    const label = singleFilter.label ? singleFilter.label : singleFilter.name;
    return (
      <div className="filter-field" data-index={index} key={`datetime${index}`}>
        <label>{label}</label>
        <input
          key={`datetimeInput${index}`}
          className="filter-datetime"
          type="text"
          placeholder=""
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
          onChange={e => {
            this._changedFilter(singleFilter, e.target.value);
          }}
        />
      </div>
    );
  }

  _getSelectField(singleFilter, index) {
    const label =
      singleFilter.label !== true ? singleFilter.label : singleFilter.name;
    let disableUnderline = true;

    if (!singleFilter.options.disableUnderline) {
      disableUnderline = false;
    } else {
      disableUnderline = true;
    }

    return (
      <div
        className="filter-field app-select"
        data-index={index}
        // Workaround for appTable, because AppSelect has hardcoded marginBottom: 20
        style={{ marginBottom: -20, ...singleFilter.style }}
      >
        {singleFilter.options.hideLabel !== true && <label>{label}</label>}
        <AppSelect
          textFieldProps={{
            disableUnderline,
            label: false,
            InputLabelProps: {
              shrink: true
            }
          }}
          {...singleFilter.options}
          onChange={itemId => {
            this._changedFilter(singleFilter, itemId, true);
          }}
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
        />
      </div>
    );
  }

  _changedFilter(filter, filterValue, reload = false, rawValue = null) {
    const filterCopy = [...this.state.filter];
    let currentFilter = null;

    if (filter.name) {
      currentFilter = filterCopy.find(x => x.name === filter.name);
    } else {
      currentFilter = filterCopy.find(x => x.field === filter.field);
    }

    if (currentFilter) {
      currentFilter.value = filterValue;
      currentFilter.rawValue = rawValue;
      filterCopy[filterCopy.indexOf(currentFilter)] = currentFilter;
    } else {
      filterCopy.push({
        ...filter,
        value: filterValue,
        rawValue
      });
    }

    this.setState(
      {
        filter: filterCopy
      },
      () => {
        if (reload) {
          this._triggerFilter();
        }
      }
    );
  }

  _triggerFilter() {
    this.props.onFilter(this.state.filter);
  }

  _clearFilter() {
    const newFilter = [...this.state.filter];
    newFilter.forEach((filter, index) => {
      newFilter[index].value = '';
      newFilter[index].rawValue = null;
    });

    this.setState(
      {
        filter: newFilter
      },
      () => {
        if ($('.filter-datetime').length) {
          $('.filter-datetime')
            .data('DateTimePicker')
            .clear();
        }

        this.props.onFilter(this.state.filter);
      }
    );
  }

  _getClearFilterButton() {
    let allEmpty = true;

    this.state.filter.forEach(filter => {
      if (filter.value) allEmpty = false;
    });

    if (allEmpty) return null;

    return (
      <button
        type="button"
        className="button-info mx-1 my-0"
        onClick={() => this._clearFilter()}
      >
        Clear
      </button>
    );
  }

  render() {
    const elements = this._getElements(this.props);

    if (elements.length === 0) return null;

    const clearFilterButton = this._getClearFilterButton();

    return (
      <form
        onSubmit={() => this._triggerFilter()}
        style={{
          padding: this.props.padded ? 10 : 0,
          display: 'flex',
          // alignItems: 'center'
          alignItems: 'flex-end'
        }}
      >
        {elements}
        {clearFilterButton}
        {this.props.noSearchButton ? null : (
          <button
            type="submit"
            className="button-round mx-1 my-0"
            // style={{ marginTop: this.props.columnForm ? 20 : 0 }}
            onClick={e => {
              e.preventDefault();
              this._triggerFilter();
              return false;
            }}
          >
            Search
          </button>
        )}
      </form>
    );
  }
}

AppTableFilter.defaultProps = {
  onInit: () => {},
  onFilter: () => {},
  format: 'DD/MM/YYYY HH:mm:ss'
};

export default withRouter(AppTableFilter);
