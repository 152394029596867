import React from 'react';

export default class AppBadge extends React.Component<any, any> {
  static defaultProps: { icon: null; text: string; type: string };

  render() {
    let icon = null as any;

    if (this.props.icon) {
      icon = <i className={`fa fa-${this.props.icon}`} />;
    }

    let { text } = this.props;

    if (icon) text = ` ${text}`;

    return (
      <span className={`badge badge-${this.props.type}`}>
        {icon} {text}
      </span>
    );
  }
}

AppBadge.defaultProps = {
  icon: null,
  text: 'Success',
  type: 'success'
};
