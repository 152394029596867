import Api from '../api/Api';
import Config from '../../config';

export default class BaseModel {
  constructor(data = null) {
    this.data = data;
  }

  getMethod() {
    return 'POST';
  }

  getSubmitUrl() {}

  getSubmitMethod() {
    return 'POST';
  }

  getListUrl() {
    return null;
  }

  getListRequestData(params) {
    return params;
  }

  getDetailsUrl() {
    return null;
  }

  getDetailsRequestData(id) {
    return {
      id
    };
  }

  getEmptyRecord() {
    return {};
  }

  getData() {
    if (this.data) {
      return this.data;
    }
    return this.getEmptyRecord();
  }

  async fetch(params = { page: '1', limit: '', value: '' }) {
    const url = new URL(Config.url + this.getListUrl(params));
    url.search = new URLSearchParams(params);

    const getUrl = this.getListUrl(params) + url.search;

    const result = await Api.call(
      getUrl,
      this.getMethod(),
      this.getListRequestData(params)
    );

    return result;
  }

  async fetchFlat(params = {}) {
    const result = await Api.call(this.getListUrl(params), this.getMethod(), {
      ...this.getListRequestData(params),
      flat: true
    });

    return result.data;
  }

  async get(id, isIdInUrl = false) {
    let result = null;

    if(isIdInUrl){
      result = await Api.call(
        this.getDetailsUrl(id),
        this.getMethod(),
      );
    }else{
      result = await Api.call(
        this.getDetailsUrl(),
        this.getMethod(),
        this.getDetailsRequestData(id)
      );
    }

    return result.data;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {};
  }

  /**
   * Custom (model specific) validation
   */
  customValidation() {
    return [];
  }
}
