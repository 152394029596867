import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'reports';

export default class ReportsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'reports.*';
  }

  getNavigation() {
    return [
      {
        label: 'Reports',
        icon: 'file',
        path: null,
        permission: 'reports.*',
        children: [
          {
            label: 'Totals',
            path: '/reports/totals',
            icon: 'globe',
            permission: 'reports.totals.*'
          },
          {
            label: 'Cards',
            path: '/reports/cards',
            icon: 'credit-card',
            permission: 'reports.cards.*'
          }
          // {
          //   label: 'Cardholders',
          //   path: '/reports/cardholders',
          //   icon: 'balance-scale'
          // },
          // {
          //   label: 'Fees',
          //   path: '/reports/fees',
          //   icon: 'balance-scale'
          // },
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}reports/cards`}
        load={() => import('./pages/cards/CardsReports')}
      />,
      <AppRoute
        path={`${currentUrl}reports/totals`}
        load={() => import('./pages/totals/TotalsReports')}
      />
    ];
  }
}
